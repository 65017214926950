.article-pane {
    font-family: 'Arial', sans-serif;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
    color: white;
    min-height: 300px;
    background-size: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* shadow for depth */
    border-radius: 8px;
  }
  
  .article-overlay {
    position: absolute; /* Position relative to the .article container */
    bottom: 0; /* Align to the bottom of the container */
    left: 0;
    right: 0; /* Stretch across the full width of the container */
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    box-sizing: border-box; /* Include padding in the element's total width and height */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    border-radius: 0 0 8px 8px; /* Rounded corners only at the bottom */
  }
  
  .article-overlay h3 {
    font-size: 24px; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* text shadow for better readability */
  }
  
  .article-overlay p {
    font-size: 16px; 
  }
  
  .article-pane:hover .article-overlay {
    background: rgba(0, 0, 0, 0.7); /* Slightly darker on hover */
  }
  
  
  @media (max-width: 768px) {
    .article-pane {
      min-height: 200px; /* Adjust height for smaller screens */
    }
  }
  
  