.loading-article{
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; 
  width: 100%; 
}

.manage-article-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%;
  height: 100%;
  padding:10px;
}

.manage-article {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px 10px 40px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.form-header {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
} 

.article-edit {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.article-title-input, .article-author-input, .article-type-select {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.6;
}

.paragraph-textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 5px;
  font-size: 16px; /* Adjust as needed */
  line-height: 1.6;
  resize: vertical; /* Allow vertical resize */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
}

.paragraph-textarea:hover {
  border-color: #007bff; /* Change on hover */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle shadow on hover */
}

.article-title-input {
  font-size: 20px;
}

.paragraph-edit {
  position: relative;
  margin-bottom: 10px;
}

textarea{
  height:140px;
}

.delete-paragraph-btn {
  position: absolute;
  top: -10px; 
  left: -10px; 
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.icon-style {
  color: #3d2a2a; 
}

.text-style {
  color: #000000; 
  margin-left: 5px; 
  font-family: Arial, sans-serif; 
  font-weight: bold;
}

.approval-actions {
  width: 100%; /* Full width of the container */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px; /* Space between buttons and form */
  gap: 10px;
}

.update-actions {
  display: flex;
  justify-content: center; /* Center the Save Changes button */
  gap: 10px;
}

.article-actions {
  margin-top: 0px;
  display: flex;
  justify-content: flex-start; /* Align Add Paragraph button to the left */
  gap: 10px;
  margin-bottom: 10px;
}

.paragraph-label {
  font-size: 14px;
  color: #333; /* Dark gray for a professional look */
  margin-bottom: 8px;
  display: block;
  font-family: Arial, sans-serif; /* A professional and clean font */
}

@media (max-width: 768px) {

  .manage-article{
    padding: 5px;
  }

  .article-edit {
    padding: 15px;
  }

  .article-title-input, .article-author-input, .article-type-select, .paragraph-textarea {
    padding: 10px;
  }

  .article-title-input {
    font-size: 16px;
  }
}
