.authentication-container{
  margin-top:50px;
}

.logo-container {
    display: flex;
    justify-content: center; /* Horizontally center the logo */
    margin-bottom:20px;
  }

  .logo {
    width: 50px; /* Adjust the width of the logo as needed */
    height: auto !important; /* Maintain aspect ratio */
  }

.form-container {
    background-color: #FFFFFF;
    max-width: 280px;
    width: 280px;
    height: auto;
    display: flex;            /* Align children using flexbox */
    padding-top:10px;
    padding-left:25px;
    padding-right:25px;
    padding-bottom: 25px;
    box-sizing: border-box;   /* Include padding in the box model */
    border-radius: 8px;
}

.form{
    display: flex;
    flex-direction: column;
    width: 100%;              /* Form should fill the container */
    max-height: 100%;         /* Form should not exceed container height */
}
.form-title{
    font-size: 16px;
    padding-bottom: 5px;
    font-family: 'Russo One';
    color: #000000;
    display: flex;            /* Align children using flexbox */
    justify-content: center;  /* Center children horizontally */
}
.form-label{
    font-size: 10px;
    font-family: 'Russo One';
    color: #000000;
    display: flex;            /* Align children using flexbox */
    justify-content: center;  /* Center children horizontally */
    margin-bottom: .2rem !important;
}

.form-link{
    font-size: 10px;
    font-family: 'Russo One';
    color: #003C70;
    display: flex;            /* Align children using flexbox */
    justify-content: center;  /* Center children horizontally */
    text-decoration: none;
    margin-top:2px;
}

.form-link:hover{
    text-decoration: underline;
    color: #0050A0;
}

.button-container{
    padding: 12px 8px;
}

a {
    text-decoration: none;
}

#forgot-password-link {
    display: block;
    text-align: right;
    font-size: 10px;
    font-family: 'Russo One';
    color: #003C70;
    margin-right:2px;
    margin-top: 5px;
    text-decoration: none;
  }
  
  #forgot-password-link:hover{
    text-decoration: underline;
    color: #0050A0;
  }

  .error-message {
    position: relative; /* Add this to make it a reference for absolute positioning */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    padding: 10px;
    font-size: 12px;
    margin-top:10px;
  }
  
  .error-text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF !important;
  }
  
  .close-error {
    position: absolute;
    top: 5px; /* Adjust as needed */
    right: 5px; /* Adjust as needed */
    background-color: transparent; /* Optional: makes the button background transparent */
    color: #FFFFFF; /* Set the color of 'X' to white */
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  