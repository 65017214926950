.image-selector {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .image-grid img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s;
  }
  
  .image-grid img.selected {
    border-color: #007bff; /* or any highlight color */
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  