.navbar-nav{
    padding-right:10px !important;
    padding-left: 10px !important;
}

.navbar {
    height: auto !important;
    padding: 0 !important;
    display: flex; 
    justify-content: space-between;
    align-items: center; 
    z-index: 1000; 
    background-color: #FFFFFF !important;
}

.navbar ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}
.navbar a {
    color: inherit;
    font-family: 'Russo One';
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.logo {
    height: 50px; 
}

.logo-title{
    color: #000000;
    font-family: 'Russo One';
    font-size:24px;
}

.header-link{
    text-decoration: none;
}

.nav-link{
    background-color: transparent;
    font-family: 'Russo One';
    color: #003C70 !important;
}

.navbar-toggler{
    margin-right: 10px;
}

nav.navbar .navbar-collapse {
    background-color: #FFFFFF !important;
    width:100% !important;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
  
.nav-item {
    margin-left: 10px; 
}

.nav-item-btn{
    display: flex;
    align-items: center;
}

#navbarDropdown:hover{
    text-decoration: underline;
    color: #0050A0 !important;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    border: 0 !important;
}

.dropdown-item {
    padding-left: 24px !important;
    padding-right: 24px !important;
    color: #003C70 !important;
    font-family: 'Russo One' !important;
}

.dropdown-item:hover {
    color: #0050A0 !important;
    background-color: #FFFFFF !important;
    text-decoration: underline;
}
