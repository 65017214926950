.confirmation-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
  }
  
  .confirmation-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .confirmation-modal-content {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .confirmation-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  /* Optional: Add a simple fade-in effect for the modal */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modal-backdrop {
    animation: fadeIn 0.3s;
  }
  