.manage-articles{
  display: flex;
  flex-grow: 1 !important;
  flex-direction: column;
  width: 100%;
  padding: 10px 5% 0px 5%;
}

.manage-articles-controls {
  width: 100%; /* Full width of the container */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px; /* Space between buttons and form */
  gap: 10px;
}

.articles {
  background-color: #f4f4f4; 
  padding: 20px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 10px 0; 
  width: 100%;
}

.articles:first-of-type {
  margin-top: 0;
}

.article-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: space-around;
  margin: 0 auto;
  max-width: 960px; /* Increased max-width for better layout */
}

.article-list-container > div {
  flex: 1 1 calc(33.333% - 20px); 
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow for each article pane */
  border-radius: 4px; /* Rounded corners for article panes */
  overflow: hidden; /* Ensures content fits within the borders */
}

.article-status-label {
  font-family: 'Russo One';
  font-size: 1.4em; /* Slightly larger for prominence */
  color: #000000;
  text-align: left;
  text-transform: uppercase;
  background-color: #ffffff; /* Changed to white for contrast */
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}