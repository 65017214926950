.loading-article{
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; 
  width: 100%; 
}

.article-wrapper{
  height: 100%;
  width: 100%;
  background-color: #eeeeee;
}

.article {
  max-width: 1200px; /* Maximum width of the article */
  overflow: auto;
  box-sizing: border-box;
  padding: 30px;
  /* background: #f4f4f4; */
  color: #333333;
  margin: 0 auto; /* Centering the article */
}

.article-content {
  background: #FFFFFF;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-header h1, .article-header p {
  text-align: center;
}

.article-header h1 {
  font-size: 32px;
  font-weight: 600; 
  color: #333333;
}

.article-author {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666666;
  text-align: left; /* or 'center' */
}

.article-date {
  color: #888;
  font-size: 14px;
}

.article-author .author-name {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.article-body p {
  font-size: 18px;
  line-height: 1.8;
}
.article-header p {
  color: #666666;
  font-size: 16px;
}

.article-body {
  font-size: 18px;
  line-height: 1.6;
  color: #444444;
}

@media (max-width: 768px) {
  .article {
    padding: 10px;
  }

  .article-content {
    padding: 20px;
  }

  .article-header h1 {
    font-size: 24px;
  }
}
