.default-btn, .approve-btn, .deny-btn, .cancel-btn{
    color: #FFFFFF;
    font-family: 'Russo One';

    border:none;

    /* Centering the button */
    margin-left: auto;
    margin-right: auto;
    display: block; /* Makes the button a block-level element */

    /* Curve */
    border-radius: 8px;

    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.default-btn {
    background-color: #003C70; 
}

.approve-btn{
    background-color: #268a2a;
}

.deny-btn{
    background-color: #dc3545;
}

.cancel-btn{
    background-color: #6c757d;
}

.default-btn:hover {
    background-color: #0050A0; /* Slightly lighter blue */
}

.approve-btn:hover{
    background-color: #4CAF50;
}

.deny-btn:hover {
    background-color: #f64557;
}

.cancel-btn:hover{
    background-color: #8a8f96;
}

.default-btn:hover, .approve-btn:hover, .deny-btn:hover, .cancel-btn:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transform: scale(1.03); /* Slightly larger */
    cursor: pointer; /* Change cursor to indicate clickability */
}

.default-btn:disabled {
    background-color: #000000; 
    box-shadow: none; 
    transform: none; 
    cursor: default; 
}

.small-button{
    font-size: 12px;
    height: 40px;
    width: 110px;
}

.medium-button {
    font-size: 12px;
    height: 42px;
    width: 220px;
}

.large-button{    
    font-size: 16px;
    height:34px;
    width:100%;
}
