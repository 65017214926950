.input-container {
    display: flex; /* Make it a flex container */
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Align items to the start */
    width: 100%;
}

.input-field {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size:12px;
}

.input-error-text {
    color: rgb(255, 0, 0);
    font-size: 10px;
    margin-left:4px;
    font-weight: bold;
  }
  