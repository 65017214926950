html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #eeeeee !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom font */
@font-face {
  font-family: 'Russo One';
  src: url('../styles/fonts/ttf/RussoOne-Regular.ttf') format('truetype');
}