.home {
  background-color: #eeeeee;
  height: 100%;
  width: 100%;
}

.article-container {
  margin: 0 auto;
  max-width: 760px; 
  padding: 10px;
}