.upload-wrapper{
  padding:10px;
}

.upload-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: #fff;
  }
  
  .upload-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .upload-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .message {
    padding: 10px;
    margin-top: 15px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
  }
  
  .success {
    background-color: #28a745;
  }
  
  .error {
    background-color: #dc3545;
  }
  